import { render, staticRenderFns } from "./Waitlist.vue?vue&type=template&id=910c7416&scoped=true&"
import script from "./Waitlist.vue?vue&type=script&lang=js&"
export * from "./Waitlist.vue?vue&type=script&lang=js&"
import style0 from "./Waitlist.vue?vue&type=style&index=0&id=910c7416&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "910c7416",
  null
  
)

export default component.exports