<template>
  <div class="page-wrapper">
    
    <img alt="logo-ergonames" class="logo-ergo" src="@/assets/Logo-V4-Light.jpeg" />
    <h1 class="home-page-title">Making <b>ERGO</b> human-friendly.</h1>
    <p class="text-center"> This DeFi project creates easy to remember Ergo Names for your Ergo wallets. </p>


    <div class="alert alert-primary" role="alert">
      <a class="btn btn-outline-primary" href="https://www.youtube.com/watch?v=cb7es24QUbM" role="button">
      Ergo Names is an award winning solution in ErgoHack V </a>
    </div>

    <h3 class="home-page-title spacer-large">What can you do by owning an Ergo Name?</h3>
    <div class="row spacer">
      <div class="col-sm-6">
        <div class="card">
          <h5 class="card-header text-center">Forget your wallet address</h5>
          <div class="card-body">
            <p class="card-text ">There is no need to copy/paste or remember your Ergo Wallet addresses. Own an Ergo Name NFT and use the Ergo Name to send and receive assets in supported apps.</p>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card">
          <h5 class="card-header text-center">Share your Ergo Name</h5>
          <div class="card-body">
            <p class="card-text">You can share your Ergo Name with friends and larger audiences to receive assets in your Ergo Wallets. Ergo Names are Twitter and Discord friendly. </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row spacer">
      <div class="col-sm-6">
        <div class="card">
          <h5 class="card-header text-center">Trade your Ergo Name</h5>
          <div class="card-body">
            <p class="card-text">Ergo Name NFT can be traded with peers right from the wallet. The Ergo Name resolution would dynamically update based on the current ownership of NFT.</p>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card">
          <h5 class="card-header text-center">Sell your Ergo Name</h5>
          <div class="card-body">
            <p class="card-text">Ergo Name NFT can be sold on any auction house just like any other NFT. We will also provide a verification service to prevent fraud.</p>
          </div>
        </div>
      </div>
    </div>

    <h3 class="home-page-title spacer-large">How Ergo Names work behind the scene?</h3>

    <div id="carouselExampleSlidesOnly" class="carousel slide spacer" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img alt="logo-ergonames" class="d-block w-100" src="@/assets/ergo-tech-stack.jpg" />
        </div>
      </div>
    </div>
    <div class="alert alert-secondary spacer" role="alert">
      Ergo Names DeFi project provides a <b>Ergo Name NFT Minting Service</b> to register new Ergo Names and <b>Ergo Name Resolution Service</b> to resolve minted Ergo Names into corresponding Ergo wallet address.
    </div>

    <h3 class="home-page-title spacer-large">Why should you own an Ergo Name?</h3>    

    <div class="text-center">
      <div class="card spacer">
        <div class="card-header">
          <b>Decentralised System</b> you can <b><span class="callout-verb">Trust</span></b>
        </div>
        <div class="card-body">
          <p class="card-text">Ergo Name ownership data is maintained on <b>Layer-1 of Ergo blockchain</b>.</p>
        </div>
      </div>    
      <div class="card spacer">
        <div class="card-header">
          <b>Simple Pricing</b> you can <b><span class="callout-verb">Understand</span></b>
        </div>
        <div class="card-body">
          <p class="card-text">Ergo Name pricing is based on <b>transparent algorithm</b>. Code is king.</p>
        </div>
      </div> 
      <div class="card spacer">
        <div class="card-header">
          <b>Instant NFT</b> you can <b><span class="callout-verb">Mint</span></b>
        </div>
        <div class="card-body">
          <p class="card-text">Ergo Name registration is instant based on <b>Smart Contracts</b> and <b>dApp connector</b>.</p>
        </div>
      </div> 
      <div class="card spacer">
        <div class="card-header">
          <b>Permanent Token</b> you can <b><span class="callout-verb">Own</span></b>
        </div>
        <div class="card-body">
          <p class="card-text">Ergo Name ownership is <b>permanent</b> based on Ergo-Names NFT ownership.</p>
        </div>
      </div> 
      <div class="card spacer">
        <div class="card-header">
          <b>Secure Resolution</b> you can <b><span class="callout-verb">Use</span></b>
        </div>
        <div class="card-body">
          <p class="card-text">Ergo Name resolution code is <b>open-sourced</b> to enable wide adoption.</p>
        </div>
      </div> 
    </div>

    <h3 class="home-page-title spacer-large">Comparison with Traditional DNS</h3>    
    <table class="table table-hover">
     <thead>
        <tr>
          <th scope="col">Key Features of DNS</th>
          <th scope="col">Web 2.0</th>
          <th scope="col">Web 3.0</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">1. Domain Registration</th>
          <td>Register on godaddy.com</td>
          <td>Register on ergonames.com</td>
        </tr>
        <tr>
          <th scope="row">2. Domain Resolution</th>
          <td>Resolve from browser address bar</td>
          <td>Resolve from wallet address bar</td>
        </tr>
        <tr>
          <th scope="row">3. Domain Transfer</th>
          <td>Transfer using Escrow Service</td>
          <td>Peer to Peer Transfers</td>
        </tr>
        <tr>
          <th scope="row">4. Domain Sale</th>
          <td>GoDaddy Domain Auctions</td>
          <td>Ergo Auctions</td>
        </tr>
      </tbody>
    </table>

    <h3 class="home-page-title spacer-large">Frequently Asked Questions (FAQs)</h3> 
    
    <div class="accordion spacer" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            When would Ergo Name service launch?
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            Ergo Names development is actively ongoing. We intend to complete the development by end of May and do a beta testing in <b>April 2023</b>. Public launch would be done after successful beta testing. Launching an incomplete product would be detrimental towards the ecosystem and we don't want to take any shortcuts in the Testing phase.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
            What would Ergo Name look like?
          </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ul>
              <li>Prefix - <code> <b>~</b> </code></li>
              <li>Case - all lower-case</li>
              <li>Numbers - Allowed</li>
              <li>Space - no space</li>
              <li>Special character - 3 special characters: a dash, underscore, and a period. Not at the first or last position of the ergo name  </li>
              <li>Minimum Length - 1</li>
              <li>Maximum Length - 20</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThree">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
            What would be the price of Ergo Name NFT?
          </button>
        </h2>
        <div id="collapseThree" class="accordion-collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            A one time payment would be required at the time of minting the Ergo Name NFT. Algorithm based pricing would be applied. The algorithm would take the length of the ergo name and the rarity of the ergo name into account to determine the price. In addition to the initial payment, a single digit percentage based royalty would be applied as per <a href="https://github.com/ergoplatform/eips/blob/master/eip-0024.md" role="button">EIP-0024  </a> standard.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFour">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
            How would funds collected from Ergo Name be used?
          </button>
        </h2>
        <div id="collapseFour" class="accordion-collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            The revenue collected from Ergo Names project would be used to offset operating costs, incetivise partners and developers and to maintain funds for future expansion of project in a multi-sig DAO.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFive">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
            How is this project different from AdaHandles?
          </button>
        </h2>
        <div id="collapseFive" class="accordion-collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            There are fundamental differences between Cardano and Ergo in how resolution of NFT works. Ergo Names is optimised for Ergo resolution. Additionally, we plan to allow Minting of NFT using Smart Contracts and dApp connector.
          </div>
        </div>
      </div>      
    </div>

    <h3 class="home-page-title spacer-large">Follow Us</h3> 
    <div class="h1 icons">
      
      <a href="https://twitter.com/ergonames" title="Twitter">
        <b-icon-twitter></b-icon-twitter>
      </a>
      <a href="https://www.youtube.com/channel/UC8981bAV8t-YzxcwxZo3wrw" title="YouTube">
        <b-icon-youtube></b-icon-youtube>
      </a>
      <a href="https://discord.gg/XfMMM4nXwR" title="Discord">
        <b-icon-discord></b-icon-discord>
      </a>
    </div>

  </div>



</template>

<script>
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: {
        inner: 'For Ergonauts',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc',
        },
      ],
    }
  },
  computed: mapState('app', ['appTitle']),
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo-ergo {
    height: 34rem;
  }

  .home-page-title {
    text-align: center;
  }

  .spacer {
    margin-top: 20px;
  }

  .spacer-large {
    margin-top: 80px;
  }

  .callout-verb {
    color: #0d5efd;
  }

  .icons {
    color: black;
    a {
      margin: 10px;
    }
  }

  .documentation-link {
    display: inline-block;
    font-size: 1.2rem;
    color: #fff;
    background-color: #5d6788;
    padding: 0.8rem 1.6rem;
    border-radius: 4px;
    transition: background-color 0.1s ease;
    box-sizing: border-box;
    text-decoration: none;
    width: fit-content;
    font-weight: 500;
  }
}
</style>
